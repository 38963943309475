import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import styles from './AddressInformation.scss';
import {
  SetCurbsideOutfitInfoPayload,
  SetCurbsidePayload,
  SetDineInTablePayload,
  SetDispatchTimePayload,
  SetDispatchTypePayload,
} from '../../../../state/checkout/checkout.actions.types';
import dataHooks from '../../data-hooks';
import DispatchTypeSelector from '../DispatchTypeSelector';
import DispatchTimeSelector from '../DispatchTimeSelector';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/statics/assets/icons/Error.svg';
import AddressInformationSummary from './AddressInformationSummary';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import RestaurantTakeoutDetails from './RestaurantTakeoutDetails';
import AddressInformationDelivery from './AddressInformationDelivery';
import Text from '../../core-components/Text';
import { Trans, useBi, useExperiments, useTranslation } from 'yoshi-flow-editor-runtime';
import {
  SetAddressInputErrorPayload,
  SetErrorVisibilityPayload,
  ToggleAllErrorsPayload,
  ValidateFormPayload,
} from '../../../../state/addressInformationForm/addressForm.actions.types';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error.svg';
import Button from '../Button';
import {
  Address,
  CurbsideInfo,
  getCurbsideInfo as restaurantCurbsideInfo,
  DispatchInfo,
  DispatchType,
  DisplayableOrderItem,
  Restaurant,
  validateAddress,
  ValidateAddressReason,
  validateTakeout,
  VirtualDispatchType,
  isMinimumPriceMet,
  validateAddressForLocations,
  isAddress,
  getLocationsWithTakeout,
  calcEarnedPoints,
  getLocationsWithDineIn,
} from '@wix/restaurants-client-logic';
import { DeliveryFormField } from '../../../../state/addressInformationForm/addressForm.reducer';
import { getScrollOptions } from '../CheckoutFlow/CheckoutFlow';
import { getDisplayableAddressError, getErrorKey } from './AddressInformation.helper';
import { Address as MembersAddress } from '@wix/ambassador-addresses-web/types';
import _ from 'lodash';
import SavedAddressView from './SavedAddressView';
import { OpenModalPayload, SaveAddressToServerPayload } from '../../../../state/session/session.actions.types';
import { Modals } from '../../../../core/constants';
import Spinner from '../Spinner';
import Checkbox from '../Checkbox';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import { convertToOloAddressMembersAddress } from '../../../../core/logic/addressLogic';
import CurbsidePickup from '../CurbsidePickup';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { componentSettings } from '../../componentSettings';
import DineInDetails from '../DineInDetails/index';
import { DineInInfo } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import { PartialLocation } from '../../../../core/oloApi';
import { replaceSpecialChars } from '../../../../core/logic/replaceSpecialChars';
import useDispatchTimeSelectorProps from '../../../../core/hooks/useDispatchTimeSelectorProps';
import MemberLoginCTA from '../MemberLoginCTA';
import ChooseLocationDropdown from '../ChooseLocationDropdown';
import { EarningRule } from '@wix/ambassador-loyalty-calculator/types';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';

export interface AddressInformationProps {
  restaurant: Restaurant;
  dispatchTime: number;
  totalOrderPrice: number;
  subtotal: number;
  dispatchType: VirtualDispatchType;
  done?: boolean;
  collapsed?: boolean;
  forceErrorVisibility?: boolean;
  index?: string;
  onSubmit: (locationId?: string) => void;
  onEdit: () => void;
  setDispatchType: (dispatchType: SetDispatchTypePayload) => void;
  setDispatchTime: (payload: SetDispatchTimePayload) => void;
  address: Address;
  supportedDispatchTypes: Set<DispatchType>;
  formattedAddressWithComment: string;
  fieldsErrors: {
    addressInput: boolean;
    apt: boolean;
    timingOption: boolean;
  };
  toggleAllErrors: (payload: ToggleAllErrorsPayload) => void;
  setDeliveryAddressFromForm: () => void;
  isMobile: boolean;
  selectedAddressOption: Address;
  setAddressInputError: (payload: SetAddressInputErrorPayload) => void;
  setFieldError: (payload: SetErrorVisibilityPayload) => void;
  setErrorVisibility: (payload: SetErrorVisibilityPayload) => void;
  addressInputError?: ValidateAddressReason;
  idealDeliveryArea?: DispatchInfo;
  errorOrderItem?: DisplayableOrderItem;
  saveStateToSessionStorage: () => void;
  timezone: string;
  errorsVisibility: Record<DeliveryFormField, boolean>;
  deliveryInfos: DispatchInfo[];
  describedby?: string;
  locale: string;
  isRTL?: boolean;
  savedAddresses: MembersAddress[];
  openModal: (payload: OpenModalPayload) => void;
  isLoadingAddressesFromServer?: boolean;
  saveAddressToServer: (address: SaveAddressToServerPayload) => void;
  initialCurbside?: boolean;
  initialTableNumber?: string;
  curbsideInfo?: CurbsideInfo;
  initialCurbsideOutfitInfo?: string;
  setCurbside: (isCurbside: SetCurbsidePayload) => void;
  setCurbsideOutfitInfo: (curbsideOutfitInfo: SetCurbsideOutfitInfoPayload) => void;
  validateForm: (dispatchType: ValidateFormPayload) => void;
  isAddressSelectionModalOpen?: boolean;
  locations: PartialLocation[];
  isUserLoggedIn?: boolean;
  setDineInTable: (payload: SetDineInTablePayload) => void;
  dineInInfo?: DineInInfo;
  isMAInstalled?: boolean;
  isRewardInstalled?: boolean;
  viewedFrom: 'multi-location-modal' | 'checkout';
  isMultiLocation: boolean;
  isLocationPicked: boolean;
  currentLocationId?: string;
  isCartEmpty: boolean;
  isLoyaltyEarnActive?: boolean;
  loggedInUserEmail?: string;
  customPointsName?: string;
  pointsBalance?: number;
  loginMember: () => void;
  modalOrigin?: OpenModalPayload;
  earningRules: EarningRule[];
  lockedDineInLocation?: PartialLocation;
}

interface SubmitHandle {
  submit: () => void;
}

const AddressInformation: React.ForwardRefRenderFunction<SubmitHandle, AddressInformationProps> = (
  {
    setDispatchType,
    dispatchType,
    index = '0',
    done,
    collapsed,
    onEdit,
    address,
    onSubmit,
    restaurant,
    totalOrderPrice,
    subtotal,
    dispatchTime,
    supportedDispatchTypes,
    setDispatchTime,
    formattedAddressWithComment,
    toggleAllErrors,
    setDeliveryAddressFromForm,
    isMobile,
    selectedAddressOption,
    setAddressInputError,
    setFieldError,
    initialTableNumber,
    addressInputError,
    fieldsErrors,
    idealDeliveryArea,
    errorOrderItem,
    saveStateToSessionStorage,
    setErrorVisibility,
    timezone,
    errorsVisibility,
    deliveryInfos,
    describedby,
    locale,
    savedAddresses,
    openModal,
    isLoadingAddressesFromServer,
    validateForm,
    saveAddressToServer,
    initialCurbside,
    curbsideInfo,
    initialCurbsideOutfitInfo,
    setCurbside,
    setCurbsideOutfitInfo,
    locations,
    isAddressSelectionModalOpen,
    isUserLoggedIn,
    setDineInTable,
    dineInInfo,
    isMAInstalled,
    isRewardInstalled,
    viewedFrom = 'checkout',
    isMultiLocation,
    isLocationPicked,
    currentLocationId,
    isCartEmpty,
    isLoyaltyEarnActive,
    loggedInUserEmail,
    customPointsName,
    pointsBalance,
    lockedDineInLocation,
    loginMember,
    modalOrigin,
    earningRules,
  },
  ref,
) => {
  const [localCurbsideOutfitInfo, setLocalCurbsideOutfitInfo] = useState(initialCurbsideOutfitInfo);
  const [isDineInEmptyField, setIsDineInEmptyField] = useState(false);
  const [isDineInNoLocationPicked, setIsDineInNoLocationPicked] = useState(false);
  const [tableNumber, setTableNumber] = useState(initialTableNumber);
  const [isTakeoutUnavailable, setIsTakeoutUnavailable] = useState(false);
  const [shouldSaveAddress, setShouldSaveAddress] = useState(false);
  const [curbsideOutfitInfoError, setCurbsideOutfitInfoError] = useState(false);
  const [localDispatchTime, setLocalDispatchTime] = useState<number>(dispatchTime);
  const [localDispatchType, setLocalDispatchType] = useState<VirtualDispatchType>(dispatchType);
  const [selectedLocation, setSelectedLocation] = useState<PartialLocation | undefined>(() =>
    getDefaultLocation(
      locations,
      dispatchType,
      isAddress(selectedAddressOption) ? selectedAddressOption : address,
      isLocationPicked,
      currentLocationId,
    ),
  );
  const [isCurbsideToggledOn, onCurbsideToggle] = useState(initialCurbside && !!getCurbsideInfo());

  const experiments = useExperiments();
  const { t } = useTranslation();
  const location = useLocation();
  const settings = useSettings();
  const biLogger = useBi();

  const isValidSelected = getLocationsWithDineIn(locations).some(
    (loc) => loc.currentLocationId === selectedLocation?.currentLocationId,
  );
  useEffect(() => {
    if (
      viewedFrom === 'checkout' &&
      !isAddressSelectionModalOpen &&
      (errorsVisibility.addressInput || errorsVisibility.apt || errorsVisibility.timingOption)
    ) {
      scroller.scrollTo('address-input', getScrollOptions());
    }
  }, [errorsVisibility, isAddressSelectionModalOpen, viewedFrom]);

  const validationProps = {
    locations,
    selectedLocation,
    currentLocationId,
    setSelectedLocation,
    setAddressInputError,
    setErrorVisibility,
    localDispatchType,
    isMultiLocation,
  };

  /* We need this useEffect because the address can be changed from the AddressSelectionModal component */
  useEffect(() => {
    if (isMultiLocation && localDispatchType === 'delivery' && isAddress(address)) {
      validateAddressWithLocations({ ...validationProps, addressToValidate: address });
    }
  }, [address.formatted]);

  const handleDispatchTimeSelectorChange = useCallback(
    ({ selectedDateTime }) => {
      setLocalDispatchTime(selectedDateTime);

      if (viewedFrom === 'checkout') {
        setDispatchTime({ timestamp: selectedDateTime });
      }

      setFieldError({ error: 'timingOption', value: false });
    },
    [setLocalDispatchTime, setDispatchTime, setFieldError, viewedFrom],
  );
  const appendToElement =
    (viewedFrom === 'multi-location-modal' &&
      document.querySelector(`[data-hook='${dataHooks.addressInformationModal}']`)) ||
    undefined;

  const hasSavedAddresses = !_.isEmpty(savedAddresses);
  const timeError = fieldsErrors.timingOption && (isTakeoutUnavailable || addressInputError?.type === 'unavailable');
  const hasMembersAreaIntegration = settings.get(componentSettings.hasMembersAreaIntegration);
  const shouldShowMembersCTA =
    isMAInstalled &&
    hasMembersAreaIntegration &&
    experiments.experiments.enabled('specs.restaurants.olo-client-members-area-addresses');
  const isMembersAddressEnabled = shouldShowMembersCTA && isUserLoggedIn;
  const idSuffix = Math.random();
  const addressInformationTitleId = `restaurants.address-information.title-${idSuffix}`;
  const currentAddress = hasSavedAddresses && isMembersAddressEnabled ? address : selectedAddressOption;
  const addressForMinPrice = currentAddress;
  const dispatchTimeSelectorProps = useDispatchTimeSelectorProps(selectedLocation, currentAddress);

  // update the validity of the form
  useEffect(() => {
    validateForm({ dispatchType: localDispatchType, isMultiLocation });
  }, [errorsVisibility, localDispatchType, validateForm, isMultiLocation, currentAddress]);

  useEffect(() => {
    toggleAllErrors({ value: false });
  }, [location, selectedLocation, toggleAllErrors]);

  const { displayableAmountLeft, minOrderPrice, displayableMinOrderPrice, isMinOrderPriceMet } =
    useMinOrderPriceDetails({
      location: selectedLocation,
      address: addressForMinPrice,
      dispatchTime: localDispatchTime,
      dispatchType: localDispatchType,
    });

  function getCurbsideInfo() {
    if (isMultiLocation && selectedLocation) {
      return restaurantCurbsideInfo(selectedLocation.deliveryInfos);
    } else {
      return curbsideInfo;
    }
  }

  function shouldShowCurbside() {
    if (isMultiLocation) {
      return localDispatchType === 'takeout' && !!getCurbsideInfo() && selectedLocation;
    } else {
      return localDispatchType === 'takeout' && !!getCurbsideInfo();
    }
  }

  function shouldShowMinPrice() {
    if (minOrderPrice === 0) {
      return false;
    }

    if (isMultiLocation && selectedLocation) {
      return true;
    } else if (!isMultiLocation) {
      return true;
    }
  }

  const setFieldErrorAndBi = ({ errorType }: { errorType: DeliveryFormField }) => {
    setFieldError({ error: errorType, value: true });
    biLogger.addressInformationContinueValidationError({
      locationGuid: selectedLocation?.currentLocationId,
      comment: selectedAddressOption?.comment || '',
      dispatchTime: localDispatchTime,
      dispatchTimeOption: localDispatchTime ? 'later' : 'asap',
      dispatchType: localDispatchType,
      errorReason: errorType as string,
    });
  };

  const validateAddressInputAndRunSideEffects = (addressToValidate: Address | undefined) => {
    if (isMultiLocation && addressToValidate) {
      return validateAddressWithLocations({ ...validationProps, addressToValidate });
    } else {
      const validateAddressReason = validateAddress({
        address: addressToValidate,
        restaurant,
        dispatchTime: localDispatchTime,
        totalOrderPrice,
      });

      if (validateAddressReason?.type === 'unavailable') {
        setFieldErrorAndBi({ errorType: 'timingOption' });
      }

      setAddressInputError({ validateAddressReason });
      setErrorVisibility({ error: 'addressInput', value: true });
      biLogger.addressInformationContinueValidationError({
        locationGuid: selectedLocation?.currentLocationId,
        comment: addressToValidate?.comment || '',
        dispatchTime: localDispatchTime,
        dispatchTimeOption: localDispatchTime ? 'later' : 'asap',
        dispatchType: localDispatchType,
        errorReason: validateAddressReason?.type as string,
      });

      return validateAddressReason;
    }
  };

  const submitDispatch = () => {
    if (!hasSavedAddresses || !isMembersAddressEnabled) {
      setDeliveryAddressFromForm();

      if (shouldSaveAddress && selectedAddressOption) {
        saveAddressToServer({ address: convertToOloAddressMembersAddress(selectedAddressOption), setAsDefault: false });
      }
    }
    setDispatchType({ dispatchType: localDispatchType });
    setDispatchTime({ timestamp: localDispatchTime });
    onSubmit(selectedLocation?.currentLocationId);
    toggleAllErrors({ value: false });
    saveStateToSessionStorage();

    if (viewedFrom === 'checkout') {
      biLogger.addressInformationContinue({
        locationGuid: selectedLocation?.currentLocationId,
        comment: selectedAddressOption?.comment || '',
        dispatchTime: localDispatchTime,
        dispatchTimeOption: localDispatchTime ? 'later' : 'asap',
        dispatchType: localDispatchType,
        curbsidePickupToggle: isCurbsideToggledOn && !!getCurbsideInfo(),
        curbsideAdditionalInformationContent: localCurbsideOutfitInfo,
        contactlessDineInInputLabel: localDispatchType === 'dine-in' ? dineInInfo?.label : undefined,
        contactlessDineInUOUInput: localDispatchType === 'dine-in' ? tableNumber : undefined,
        isSaved: isUserLoggedIn,
      });
    } else if (viewedFrom === 'multi-location-modal') {
      biLogger.dispatchSettingsUpdate({
        locationGuid: selectedLocation?.currentLocationId,
        dispatchTime: localDispatchTime,
        dispatchTimeOption: localDispatchTime ? 'later' : 'asap',
        dispatchType: localDispatchType,
        curbsidePickupToggle: isCurbsideToggledOn && !!getCurbsideInfo(),
        curbsideAdditionalInformationContent: localCurbsideOutfitInfo,
        contactlessDineInInputLabel: localDispatchType === 'dine-in' ? dineInInfo?.label : undefined,
        contactlessDineInUOUInput: localDispatchType === 'dine-in' ? tableNumber : undefined,
        validationError: shouldShowLocationsError() ? 'location-changed' : undefined,
      });
    }
  };

  const handleSubmitTakeout = () => {
    if (isMultiLocation && !selectedLocation) {
      setErrorVisibility({ error: 'location', value: true });
      return;
    }
    const { isValid: isTakeoutValid, reason: invalidTakeoutReason } = validateTakeout(
      isMultiLocation && selectedLocation ? selectedLocation : restaurant,
      localDispatchTime,
    );

    if (!isTakeoutValid && invalidTakeoutReason === 'unavailable') {
      setIsTakeoutUnavailable(true);
      setFieldErrorAndBi({ errorType: 'timingOption' });
    }
    if (isTakeoutValid) {
      if (shouldShowCurbside()) {
        if (isCurbsideToggledOn && getCurbsideInfo()?.additionalInformationRequired && !localCurbsideOutfitInfo) {
          setCurbsideOutfitInfoError(true);
          setErrorVisibility({ error: 'curbsideAdditionalInfo', value: true });
          biLogger.addressInformationContinueValidationError({
            errorReason: 'Curbside Pickup',
            locationGuid: selectedLocation?.currentLocationId,
            pageName: viewedFrom === 'checkout' ? 'checkout' : 'dispatch settings',
          });
          return;
        }
      }

      submitDispatch();
      setCurbside({ isCurbside: isCurbsideToggledOn && !!getCurbsideInfo() });
      setCurbsideOutfitInfo({ curbsideOutfitInfo: localCurbsideOutfitInfo });
    }
  };

  const handleSubmitDelivery = () => {
    const addressToValidate = hasSavedAddresses && isMembersAddressEnabled ? address : selectedAddressOption;
    const validateAddressReason = validateAddressInputAndRunSideEffects(addressToValidate);

    const isAptValid =
      Boolean(addressToValidate?.apt) ||
      Boolean(addressToValidate?.addressLine2) ||
      !experiments.experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery') ||
      viewedFrom === 'multi-location-modal';

    toggleAllErrors({ value: true });

    if (!isAptValid) {
      setFieldErrorAndBi({ errorType: isMembersAddressEnabled ? 'addressLine2' : 'apt' });
    }

    if (!validateAddressReason && isAptValid) {
      submitDispatch();
    }
  };

  const handleSubmitDineIn = () => {
    if (!tableNumber) {
      const isDineInLocationPicked =
        selectedLocation || (locations && getLocationsWithDineIn(locations)?.length === 1) || lockedDineInLocation;
      if (isMultiLocation && !isDineInLocationPicked) {
        setIsDineInNoLocationPicked(true);
      } else {
        setIsDineInEmptyField(true);
        setFieldErrorAndBi({ errorType: 'contactlessDineInInputLabel' });
      }
    } else {
      setDineInTable({ tableNumber });
      submitDispatch();
    }
  };

  const handleSubmit = () => {
    switch (localDispatchType) {
      case 'takeout':
        handleSubmitTakeout();
        break;
      case 'delivery':
        handleSubmitDelivery();
        break;
      case 'dine-in':
        handleSubmitDineIn();
        break;
      default:
        return;
    }
  };
  // used by AddressInformationModal
  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }));

  const setDispatchTypeAndBi = (dispatchTypePayload: SetDispatchTypePayload) => {
    // Evid: 830 - Checkout-> Dispatch update. (addToCartFailure it's a bad name)
    biLogger.addToCartFailure({
      lastState: dispatchTypePayload.dispatchType,
    });

    setLocalDispatchType(dispatchTypePayload.dispatchType);

    if (viewedFrom === 'checkout') {
      setDispatchType(dispatchTypePayload);
    }

    // when moving between tabs we want to reselect the best location for that dispatch type
    if (isMultiLocation) {
      setSelectedLocation(
        getDefaultLocation(
          locations,
          dispatchTypePayload.dispatchType,
          isAddress(selectedAddressOption) ? selectedAddressOption : address,
          isLocationPicked,
          dispatchTypePayload.dispatchType === 'dine-in' && lockedDineInLocation
            ? lockedDineInLocation.currentLocationId
            : currentLocationId,
        ),
      );
    }

    const shouldSendMinOrderError = !isMinimumPriceMet({
      totalOrderPrice: subtotal,
      restaurant,
      dispatchType: dispatchTypePayload.dispatchType,
      selectedAddressOption,
    });

    if (shouldSendMinOrderError) {
      biLogger.liveSiteMinimumOrderError({
        locationGuid: selectedLocation?.currentLocationId,
        price: totalOrderPrice,
        minimumOrder: minOrderPrice,
        pageName: 'Checkout',
        dispatchType,
      });
    }
  };

  const shouldShowLocationsError = () => {
    return (
      !isCartEmpty && isMultiLocation && selectedLocation && selectedLocation.currentLocationId !== currentLocationId
    );
  };

  const renderMemberLoginCTA = () => {
    return (
      viewedFrom === 'multi-location-modal' &&
      shouldShowMembersCTA &&
      !isLoadingAddressesFromServer && (
        <MemberLoginCTA
          dataHook={dataHooks.addressInformationMembersLogin}
          isMobile={isMobile}
          isUserLoggedIn={isUserLoggedIn}
          onLoginClick={() => {
            loginMember();
            biLogger.logInRequest({ origin: 'address-modal' });
          }}
          userEmail={loggedInUserEmail}
          onLogoutClick={() => openModal({ modal: Modals.LOGOUT_MODAL })}
          pointsToBeEarned={calcEarnedPoints({ rules: earningRules, moneyAmountInCents: subtotal })}
          customPointsName={customPointsName}
          pointsBalance={pointsBalance}
          isLoyaltyEarnActive={isLoyaltyEarnActive}
        />
      )
    );
  };

  const changeLocation = (id: string) => setSelectedLocation(_.find(locations, { currentLocationId: id }));

  const renderDelivery = () => {
    return (
      <React.Fragment>
        {isLoadingAddressesFromServer && (
          <div className={styles.spinner}>
            <Spinner data-hook={dataHooks.addressInformationSpinner} />
          </div>
        )}
        {!isLoadingAddressesFromServer && (!hasSavedAddresses || !isMembersAddressEnabled) && (
          <React.Fragment>
            <AddressInformationDelivery
              appendToElement={appendToElement}
              restaurant={restaurant}
              dispatchTime={localDispatchTime}
              totalOrderPrice={totalOrderPrice}
              onAddressInputBlur={validateAddressInputAndRunSideEffects}
              onAddressInputSelect={validateAddressInputAndRunSideEffects}
              showAddressLine2={isMembersAddressEnabled}
              className={minOrderPrice ? styles.addressInputMinPrice : ''}
              shouldDisplayAllInputs={viewedFrom === 'checkout'}
            />
            {shouldShowMinPrice() && (
              <div className={styles.deliveryMinOrderPrice}>
                <Text data-hook={dataHooks.addressInformationMinOrderPrice} typography="p2-s-secondary">
                  {t('Order_Online_MinimumOrder_Amount_Label', { amount: displayableMinOrderPrice })}
                </Text>
              </div>
            )}
          </React.Fragment>
        )}
        {!isLoadingAddressesFromServer && hasSavedAddresses && isMembersAddressEnabled && (
          <SavedAddressView
            address={address}
            onChange={() => {
              biLogger.openAddressSelectionModal({});
              openModal({ modal: Modals.ADDRESS_SELECTION });
            }}
            error={getDisplayableAddressError({
              address,
              restaurant,
              dispatchTime: localDispatchTime,
              t,
              totalOrderPrice,
              isAptRequired: experiments.experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery'),
              isMultiLocation,
              locations,
            })}
          />
        )}
        {!isLoadingAddressesFromServer && !hasSavedAddresses && isMembersAddressEnabled && (
          <Checkbox
            label={t('checkout_main_deliverymethod.saveaddress.text')}
            onChange={() => {
              const newValue = !shouldSaveAddress;
              biLogger.checkboxSaveAddressForFutureOrders({ value: newValue ? 'on' : 'off' });
              setShouldSaveAddress(newValue);
            }}
            checked={shouldSaveAddress}
            data-hook={dataHooks.addressInformationSaveAddressCheckbox}
            name={dataHooks.addressInformationSaveAddressCheckbox}
            className={styles.checkbox}
          />
        )}
        {!isLoadingAddressesFromServer && isMultiLocation && (
          <ChooseLocationDropdown
            appendToElement={appendToElement}
            className={styles.locationPicker}
            labelText={t('order_settings_modal_choose_location_label')}
            filters="delivery"
            onChange={(id: string) => {
              changeLocation(id);
              biLogger.locationPicked({
                dispatchType: 'delivery',
                locationGuid: id,
                pageName: modalOrigin?.context?.origin,
                validationError: shouldShowLocationsError() ? 'location-changed' : undefined,
              });
            }}
            location={selectedLocation?.currentLocationId}
          />
        )}
      </React.Fragment>
    );
  };

  const renderTakeout = () => {
    return (
      <React.Fragment>
        <RestaurantTakeoutDetails
          appendToElement={appendToElement}
          formattedAddressWithComment={formattedAddressWithComment}
          className={shouldShowMinPrice() || selectedLocation}
          onLocationChange={(id: string) => {
            changeLocation(id);
            setErrorVisibility({ error: 'location', value: false });
            biLogger.locationPicked({
              dispatchType: 'pickup',
              locationGuid: id,
              pageName: modalOrigin?.context?.origin,
              validationError: shouldShowLocationsError() ? 'location-changed' : undefined,
            });
          }}
          selectedLocation={selectedLocation?.currentLocationId}
        />
        {(shouldShowMinPrice() || selectedLocation) && (
          <div className={styles.takeoutLocationInfo}>
            {shouldShowMinPrice() && (
              <Text data-hook={dataHooks.addressInformationMinOrderPrice} typography="p2-s-secondary">
                {t('Order_Online_MinimumOrder_Amount_Label', { amount: displayableMinOrderPrice })}
              </Text>
            )}
            {selectedLocation && (
              <TextButton
                className={styles.showMapLink}
                priority={TEXT_BUTTON_PRIORITY.link}
                onClick={() => {
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      selectedLocation.address.formatted,
                    )}`,
                  );
                  biLogger.pickupShowOnMapClick({
                    locationGuid: currentLocationId,
                    pageName: viewedFrom === 'checkout' ? 'checkout' : 'dispatch settings',
                  });
                }}
                data-hook={dataHooks.addressInformationShowMap}
              >
                {t('order_settings_modal_showmap_cta')}
              </TextButton>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  const onChangeTableNumber = (value: string) => {
    /* Validate that the tableNumber contains only letters and digits */
    const validValue = replaceSpecialChars(value);

    if (validValue !== tableNumber) {
      setIsDineInEmptyField(false);
      setTableNumber(validValue);
    }
  };

  const renderDineIn = () => {
    return (
      <DineInDetails
        tableNumber={tableNumber}
        appendToElement={appendToElement}
        onChangeTableNumber={onChangeTableNumber}
        shouldShowError={isDineInEmptyField}
        showNoLocatonPickedError={isDineInNoLocationPicked}
        onLocationChange={(id: string) => {
          setIsDineInNoLocationPicked(false);
          setIsDineInEmptyField(false);
          changeLocation(id);
        }}
        selectedLocation={
          lockedDineInLocation?.currentLocationId || (isValidSelected ? selectedLocation?.currentLocationId : undefined)
        }
      />
    );
  };

  const shouldDisplayTimeSelector = () => {
    if (localDispatchType === 'dine-in') {
      return false;
    }

    if (isMultiLocation) {
      if (!selectedLocation) {
        return false;
      }

      if (localDispatchType === 'delivery') {
        return isAddress(currentAddress);
      }
    }

    return true;
  };

  return (
    <div
      data-hook={dataHooks.addressInformation}
      className={!isMultiLocation ? styles.wrapper : ''}
      aria-labelledby={addressInformationTitleId}
      aria-describedby={describedby}
    >
      {viewedFrom === 'checkout' && (
        <CheckoutFlowStepTitle
          text={t('checkout_main_delivery_method')}
          done={done}
          collapsed={collapsed}
          index={index}
          onEdit={onEdit}
          editButtonDataHook={dataHooks.checkoutSummaryLineEditAddress}
          titleId={addressInformationTitleId}
        />
      )}

      {!done && !collapsed && supportedDispatchTypes.size > 1 && (
        <DispatchTypeSelector
          className={styles.selector}
          dispatchType={localDispatchType}
          setDispatchType={setDispatchTypeAndBi}
          supportedDispatchTypes={supportedDispatchTypes}
          isMobile={isMobile}
        />
      )}

      {!done && !collapsed && (
        <form
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            handleSubmit();
          }}
          data-hook={dataHooks.addressInformationForm}
        >
          {renderMemberLoginCTA()}
          {localDispatchType === 'delivery' && renderDelivery()}
          {localDispatchType === 'takeout' && renderTakeout()}
          {localDispatchType === 'dine-in' && renderDineIn()}

          {shouldDisplayTimeSelector() && (
            <DispatchTimeSelector
              {...dispatchTimeSelectorProps}
              dispatchTime={localDispatchTime}
              timingOption={localDispatchTime ? 'later' : 'asap'}
              dispatchType={localDispatchType}
              onChange={handleDispatchTimeSelectorChange}
              error={timeError ? t('checkout_main_delivery_time_errormessage') : undefined}
              isModal={viewedFrom === 'multi-location-modal'}
            />
          )}
          {shouldShowCurbside() && (
            <CurbsidePickup
              setCurbsideOutfitInfo={(info: string) => {
                setLocalCurbsideOutfitInfo(info);
                setErrorVisibility({ error: 'curbsideAdditionalInfo', value: info === '' });
              }}
              curbsideOutfitInfo={localCurbsideOutfitInfo}
              isCurbsideOn={isCurbsideToggledOn}
              onCurbsideToggle={(isOn: boolean) => {
                setErrorVisibility({ error: 'curbsideAdditionalInfo', value: isOn && localCurbsideOutfitInfo === '' });
                onCurbsideToggle(isOn);
              }}
              curbsideInfo={getCurbsideInfo()}
              outfitInfoError={curbsideOutfitInfoError}
            />
          )}

          {errorOrderItem && getErrorKey(errorOrderItem) && (
            <SectionNotification type="error" className={styles.error} data-hook={dataHooks.addressInformationError}>
              <SectionNotification.Icon icon={<Error />} />
              <SectionNotification.Text>
                <Trans
                  t={t}
                  i18nKey={getErrorKey(errorOrderItem)}
                  components={[
                    <Link data-hook={dataHooks.addressInformationErrorLink} to="/cart">
                      placeholder
                    </Link>,
                  ]}
                />
              </SectionNotification.Text>
            </SectionNotification>
          )}

          {!isMinOrderPriceMet && viewedFrom === 'checkout' && (
            <SectionNotification
              type="error"
              className={styles.error}
              data-hook={dataHooks.addressInformationMinOrderPriceErrorBanner}
            >
              <SectionNotification.Icon icon={<Error />} />
              <SectionNotification.Text>
                <Trans
                  t={t}
                  i18nKey={'checkout_main_order_minprice_errormessage_with_link'}
                  components={[
                    displayableAmountLeft,
                    <Link data-hook={dataHooks.addressInformationMinOrderPriceErrorBannerLink} to="/">
                      placeholder
                    </Link>,
                  ]}
                />
              </SectionNotification.Text>
            </SectionNotification>
          )}
          {shouldShowLocationsError() && (
            <SectionNotification
              className={styles.locationError}
              type="error"
              data-hook={dataHooks.addressInformationNotificationBar}
            >
              <SectionNotification.Icon data-hook="error-icon" icon={<ErrorIcon />} />
              <SectionNotification.Text>{t('order_settings_modal_emtpy_cart_error')}</SectionNotification.Text>
            </SectionNotification>
          )}
          {viewedFrom === 'checkout' && (
            <Button
              upgrade
              fullWidth
              priority={PRIORITY.primary}
              className={styles.button}
              data-hook={dataHooks.addressInformationContinue}
              type="submit"
              disabled={!!errorOrderItem || !isMinOrderPriceMet}
            >
              <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
            </Button>
          )}
        </form>
      )}

      {done && (
        <AddressInformationSummary
          dineInInfo={dineInInfo}
          address={localDispatchType === 'delivery' ? address : restaurant.address}
          dispatchType={localDispatchType}
          dispatchTime={localDispatchTime}
          timezone={timezone}
          locale={locale}
          tableNumber={initialTableNumber}
          deliveryInfos={deliveryInfos}
          idealDeliveryArea={idealDeliveryArea}
          isCurbsideToggledOn={isCurbsideToggledOn}
          curbsideOutfitInfo={initialCurbsideOutfitInfo}
          curbsideInfo={curbsideInfo}
        />
      )}
    </div>
  );
};

AddressInformation.displayName = 'AddressInformation';

/**
 * @returns a location to be selected for the user by default
 * based on dispatch type, current location, if location was picked and the address
 */
function getDefaultLocation(
  locations: PartialLocation[],
  dispatchType: VirtualDispatchType,
  selectedAddressOption: Address,
  isLocationPicked: boolean,
  currentLocationId?: string,
) {
  if (dispatchType === 'delivery' && isAddress(selectedAddressOption)) {
    const validatedLocations = validateAddressForLocations(locations, selectedAddressOption);
    if (validatedLocations.hasLocations && validatedLocations.locations) {
      return _.find(validatedLocations.locations, { currentLocationId }) || validatedLocations.locations[0];
    }
  } else if (dispatchType === 'takeout') {
    const takeoutLocations = getLocationsWithTakeout(locations);
    if (takeoutLocations.length === 1) {
      return takeoutLocations[0];
    } else if (isLocationPicked) {
      return _.find(takeoutLocations, { currentLocationId });
    }
  } else if (dispatchType === 'dine-in') {
    if (isLocationPicked) {
      return _.find(locations, { currentLocationId });
    }
    const locationsWithDineIn = getLocationsWithDineIn(locations);

    if (locationsWithDineIn.length < 2) {
      return locationsWithDineIn[0];
    }
  }
  return undefined;
}

interface ValidateAddressWithAllLocationsArgs {
  locations: PartialLocation[];
  addressToValidate: Address;
  selectedLocation?: PartialLocation;
  currentLocationId?: string;
  setSelectedLocation: (loc?: PartialLocation) => void;
  setAddressInputError: (payload: SetAddressInputErrorPayload) => void;
  setErrorVisibility: (payload: SetErrorVisibilityPayload) => void;
  isMultiLocation: boolean;
  localDispatchType: VirtualDispatchType;
}

function validateAddressWithLocations({
  locations,
  addressToValidate,
  selectedLocation,
  currentLocationId,
  setSelectedLocation,
  setAddressInputError,
  setErrorVisibility,
}: ValidateAddressWithAllLocationsArgs) {
  const { hasLocations, locations: validLocations, reason } = validateAddressForLocations(locations, addressToValidate);
  setAddressInputError({ validateAddressReason: reason });

  if (hasLocations && validLocations) {
    // if the selected location is in the bestLocation array do nothing
    let aLocation = validLocations.find((loc) => loc.currentLocationId === selectedLocation?.currentLocationId);
    setErrorVisibility({ error: 'addressInput', value: false });

    // else set the selected location to the currentLocationId(the location we are in) or the first best location
    if (!aLocation) {
      aLocation = validLocations.find((loc) => loc.currentLocationId === currentLocationId) || validLocations[0];
      setSelectedLocation(aLocation);
      setErrorVisibility({ error: 'location', value: false });
      return;
    }
  } else if (reason) {
    setErrorVisibility({ error: 'addressInput', value: true });
    return reason;
  }
}

export default forwardRef(AddressInformation);
